import { create } from "zustand";

type OnboardingDrawerState = {
  onboardingDrawerEnabled: boolean;
  setOnboardingDrawerEnabled: (enabled: boolean) => void;
};

const useOnboardingDrawerStore = create<OnboardingDrawerState>((set) => ({
  onboardingDrawerEnabled: false,
  setOnboardingDrawerEnabled: (enabled) =>
    set({ onboardingDrawerEnabled: enabled }),
}));

export { useOnboardingDrawerStore };
