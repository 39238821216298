import * as React from "react";

import { Input, InputProps } from "./input";

export interface InputRateProps extends Omit<InputProps, "mask" | "value"> {
  value?: number | string;
}

function inputRateParser(value?: string): number {
  return value
    ? Math.min(Math.max(parseInt(value.replace(/\D/g, "")) / 100, 0), 100)
    : 0;
}

function inputRateFormatter(value?: string | number): string {
  if (!value) return "";

  return Number(value).toFixed(2);
}

const InputRate = React.forwardRef<HTMLInputElement, InputRateProps>(
  ({ className, value, onChange, ...props }, ref) => {
    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
      if (onChange) {
        const value = event.target.value;

        onChange({
          ...event,
          target: {
            ...event.target,
            // @ts-ignore
            value: inputRateParser(value),
          },
        });
      }
    }

    return (
      <Input
        ref={ref}
        value={inputRateFormatter(value)}
        onChange={handleChange}
        {...props}
      />
    );
  }
);
InputRate.displayName = "InputRate";

export { InputRate };
