import { Adapter } from "@/@types";
import { CategoryKind } from "@/@types/category";
import { Receivable } from "@/@types/entries";
import {
  ConciliationReceivablePayload,
  CreateReceivablePayload,
  MoveReceivables,
  ReceivableConciliation,
  UpdateReceivablePayload,
  UpdateReceivables,
} from "@/@types/entries/receivable";

export class ReceivableService {
  constructor(private readonly http: Adapter) {}

  async createReceivable(
    companyId: string,
    payload: CreateReceivablePayload
  ): Promise<Receivable> {
    return this.http
      .post(`/v1/companies/${companyId}/receivables`, payload)
      .then((response) => response.data);
  }

  async getConciliationReceivable(
    companyId: string,
    receivableId: string
  ): Promise<ReceivableConciliation> {
    return this.http
      .get(
        `/v1/companies/${companyId}/receivables/${receivableId}/conciliation`
      )
      .then((response) => response.data);
  }

  async conciliationReceivable(
    companyId: string,
    payload: ConciliationReceivablePayload
  ): Promise<Receivable> {
    return this.http
      .post(`/v1/companies/${companyId}/receivables/conciliation`, payload)
      .then((response) => response.data);
  }

  async undoConciliationReceivable(
    companyId: string,
    payload: ConciliationReceivablePayload
  ): Promise<Receivable> {
    return this.http
      .post(`/v1/companies/${companyId}/receivables/conciliation/undo`, payload)
      .then((response) => response.data);
  }

  async updateReceivable(
    companyId: string,
    { receivable_id, amount_cents, ...rest }: UpdateReceivablePayload
  ): Promise<Receivable> {
    return this.http
      .patch(`/v1/companies/${companyId}/receivables/${receivable_id}`, {
        ...rest,
        amount_cents: Number(amount_cents),
      })
      .then((response) => response.data);
  }

  async updateReceivables(
    companyId: string,
    { amount_cents, ...rest }: UpdateReceivables
  ): Promise<Receivable> {
    return this.http
      .patch(`/v1/companies/${companyId}/receivables`, {
        ...rest,
        amount_cents: Number(amount_cents),
      })
      .then((response) => response.data);
  }

  async moveReceivables(
    companyId: string,
    payload: MoveReceivables
  ): Promise<unknown> {
    return this.http
      .patch(`/v1/companies/${companyId}/receivables/move`, payload)
      .then((response) => response.data);
  }

  async getReceivable(companyId: string, billId: string): Promise<Receivable> {
    return this.http
      .get(`/v1/companies/${companyId}/receivables/${billId}`)
      .then((response) => response.data);
  }

  async getReceivables({
    companyId,
    start_date,
    end_date,
    order_by = "date",
    required_due_date,
    bank_account_ids,
    category_kind,
  }: {
    companyId: string;
    start_date?: string;
    end_date?: string;
    order_by: "date" | "sorting_index";
    required_due_date?: boolean;
    bank_account_ids?: string[];
    category_kind?: CategoryKind;
  }): Promise<ReceivableConciliation[]> {
    return this.http
      .get(`/v1/companies/${companyId}/receivables`, {
        params: {
          start_date,
          end_date,
          order_by,
          required_due_date,
          bank_account_ids: bank_account_ids?.join(";"),
          category_kind,
        },
      })
      .then((response) => response.data);
  }

  async deleteReceivable(
    companyId: string,
    billId: string
  ): Promise<Receivable> {
    return this.http
      .delete(`/v1/companies/${companyId}/receivables/${billId}`)
      .then((response) => response.data);
  }
}
