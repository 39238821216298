import { Adapter } from "@/@types";
import {
  Bill,
  BillConciliation,
  MoveBills,
  UpdateBillPayload,
  UpdateBills,
} from "@/@types/entries";
import { CreateBillPayload } from "@/@types/entries/bill/create-bill";
import { ConciliationBillPayload } from "@/@types/entries";
import { CategoryKind } from "@/@types/category";

export class BillService {
  constructor(private readonly http: Adapter) {}

  async createBill(
    companyId: string,
    payload: CreateBillPayload
  ): Promise<Bill> {
    return this.http
      .post(`/v1/companies/${companyId}/bills`, payload)
      .then((response) => response.data);
  }

  async getConciliationBill(
    companyId: string,
    billId: string
  ): Promise<BillConciliation> {
    return this.http
      .get(`/v1/companies/${companyId}/bills/${billId}/conciliation`)
      .then((response) => response.data);
  }

  async conciliationBill(
    companyId: string,
    payload: ConciliationBillPayload
  ): Promise<Bill> {
    return this.http
      .post(`/v1/companies/${companyId}/bills/conciliation`, payload)
      .then((response) => response.data);
  }

  async undoConciliationBill(
    companyId: string,
    payload: ConciliationBillPayload
  ): Promise<Bill> {
    return this.http
      .post(`/v1/companies/${companyId}/bills/conciliation/undo`, payload)
      .then((response) => response.data);
  }

  async updateBill(
    companyId: string,
    { bill_id, amount_cents, ...rest }: UpdateBillPayload
  ): Promise<Bill> {
    return this.http
      .patch(`/v1/companies/${companyId}/bills/${bill_id}`, {
        ...rest,
        amount_cents: Number(amount_cents),
      })
      .then((response) => response.data);
  }

  async updateBills(
    companyId: string,
    { amount_cents, ...rest }: UpdateBills
  ): Promise<Bill> {
    return this.http
      .patch(`/v1/companies/${companyId}/bills`, {
        ...rest,
        amount_cents: Number(amount_cents),
      })
      .then((response) => response.data);
  }

  async moveBills(companyId: string, payload: MoveBills): Promise<unknown> {
    return this.http
      .patch(`/v1/companies/${companyId}/bills/move`, payload)
      .then((response) => response.data);
  }

  async getBill(companyId: string, billId: string): Promise<Bill> {
    return this.http
      .get(`/v1/companies/${companyId}/bills/${billId}`)
      .then((response) => response.data);
  }

  async getBills({
    companyId,
    start_date,
    end_date,
    order_by = "date",
    required_due_date,
    bank_account_ids,
    category_kind,
  }: {
    companyId: string;
    start_date?: string;
    end_date?: string;
    order_by: "date" | "sorting_index";
    required_due_date?: boolean;
    bank_account_ids?: string[];
    category_kind?: CategoryKind;
  }): Promise<BillConciliation[]> {
    return this.http
      .get(`/v1/companies/${companyId}/bills`, {
        params: {
          start_date,
          end_date,
          order_by,
          required_due_date,
          bank_account_ids: bank_account_ids?.join(";"),
          category_kind,
        },
      })
      .then((response) => response.data);
  }

  async deleteBill(companyId: string, billId: string): Promise<Bill> {
    return this.http
      .delete(`/v1/companies/${companyId}/bills/${billId}`)
      .then((response) => response.data);
  }
}
