import { Adapter } from "@/@types";
import { Bill, CreateTransfer, CreateTransfers, Entry } from "@/@types/entries";
import { CreateBillPayload } from "@/@types/entries/bill/create-bill";

export class TransferService {
  constructor(private readonly http: Adapter) {}

  async getEqualTransfers(companyId: string): Promise<Entry[]> {
    return this.http
      .get(`/v1/companies/${companyId}/transfers/equal`)
      .then((response) => response.data);
  }

  async createTransfer(
    companyId: string,
    payload: CreateTransfer
  ): Promise<Bill> {
    return this.http
      .post(`/v1/companies/${companyId}/transfers`, payload)
      .then((response) => response.data);
  }

  async createTransfers(
    companyId: string,
    payload: CreateTransfers
  ): Promise<Bill> {
    return this.http
      .post(`/v1/companies/${companyId}/transfers/batch`, payload)
      .then((response) => response.data);
  }
}
