import { Adapter } from "@/@types";
import { BankAccount, BankAccountStatus } from "@/@types/accounts";

export class AccountsService {
  constructor(private readonly http: Adapter) {}

  async createAccount(
    companyId: string,
    payload: Pick<
      BankAccount,
      "date_initial_balance" | "routing_number" | "kind"
    > & {
      initial_balance_amount_cents: string | number;
    }
  ): Promise<BankAccount> {
    return this.http
      .post(`/v1/companies/${companyId}/bank_accounts`, {
        ...payload,
        initial_balance_amount_cents: payload.initial_balance_amount_cents || 0,
        date_initial_balance: payload.date_initial_balance || null,
      })
      .then((response) => response.data);
  }

  async updateAccount(
    companyId: string,
    bankAccountId: string,
    payload: Partial<
      Pick<BankAccount, "date_initial_balance" | "routing_number" | "kind"> & {
        initial_balance_amount_cents: string | number;
      }
    >
  ): Promise<BankAccount> {
    return this.http
      .patch(`/v1/companies/${companyId}/bank_accounts/${bankAccountId}`, {
        ...payload,
        initial_balance_amount_cents: payload.initial_balance_amount_cents || 0,
        date_initial_balance: payload.date_initial_balance || null,
      })
      .then((response) => response.data);
  }

  async archiveAccount(
    companyId: string,
    bankAccountId: string
  ): Promise<BankAccount> {
    return this.http
      .patch(
        `/v1/companies/${companyId}/bank_accounts/${bankAccountId}/archive`
      )
      .then((response) => response.data);
  }

  async getAccount(
    companyId: string,
    bankAccountId: string
  ): Promise<
    BankAccount & {
      current_balance: number;
    }
  > {
    return this.http
      .get(`/v1/companies/${companyId}/bank_accounts/${bankAccountId}`)
      .then((response) => response.data);
  }

  async getAccounts(companyId: string): Promise<BankAccount[]> {
    return this.http
      .get(`/v1/companies/${companyId}/bank_accounts`)
      .then((response) => response.data);
  }

  async getAccountsBalance(
    companyId: string,
    {
      routingNumber,
      status,
      startDate,
      endDate,
    }: {
      routingNumber?: string;
      status?: BankAccountStatus;
      startDate?: string;
      endDate?: string;
    } = {}
  ): Promise<BankAccount[]> {
    return this.http
      .get(`/v1/companies/${companyId}/bank_accounts/balance`, {
        params: {
          routing_number: routingNumber,
          status,
          start_date: startDate,
          end_date: endDate,
        },
      })
      .then((response) => response.data);
  }
}
