import React from "react";

import { cva, VariantProps } from "class-variance-authority";
import { cn } from "@/lib/utils";

const counterVariants = cva(
  "absolute -right-2 -top-3 flex justify-center items-center bg-blue-700 text-white rounded-full",
  {
    variants: {
      size: {
        small: "w-4 h-4 text-[10px]",
        default: "w-[8px] h-[8px] text-md",
        large: "w-[10px] h-[10px] text-md",
      },
    },
    defaultVariants: {
      size: "default",
    },
  }
);

export interface CounterProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof counterVariants> {
  number?: number;
}

export function Counter(inProps: CounterProps) {
  const { size, children, number, className, ...rest } = inProps;

  return (
    <div className="relative" {...rest}>
      <div className={cn(counterVariants({ size }), className)}>{number}</div>
      {children}
    </div>
  );
}
