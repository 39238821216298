import { Adapter } from "@/@types";
import { Attachment } from "@/@types/attachments";
import { Bill, CopyEntries, Entry, Receivable } from "@/@types/entries";

export class EntriesService {
  constructor(private readonly http: Adapter) {}

  async getEntries(
    companyId: string,
    startDate?: string,
    endDate?: string,
    bankAccountIds?: string[]
  ): Promise<Array<Entry>> {
    return this.http
      .get(`/v1/companies/${companyId}/entries`, {
        params: {
          start_date: startDate,
          end_date: endDate,
          bank_account_ids: bankAccountIds?.join(";"),
        },
      })
      .then((response) => response.data);
  }

  async getEntriesKind(
    companyId: string,
    entriesIds: string[]
  ): Promise<{
    bills: Bill[];
    receivables: Receivable[];
  }> {
    return this.http
      .get(`/v1/companies/${companyId}/entries/kind`, {
        params: {
          entries_ids: entriesIds,
        },
      })
      .then((response) => response.data);
  }

  async getAttachments(
    companyId: string,
    startDate?: string,
    endDate?: string
  ): Promise<Attachment[]> {
    return this.http
      .get(`/v1/companies/${companyId}/entries/attachments`, {
        params: {
          start_date: startDate,
          end_date: endDate,
        },
      })
      .then((response) => response.data);
  }

  async deleteEntries(
    companyId: string,
    entries: string[]
  ): Promise<{ bills: number; receivables: number }> {
    return this.http
      .patch(`/v1/companies/${companyId}/entries/delete`, {
        entries,
      })
      .then((response) => response.data);
  }

  async undoDeleteEntries(
    companyId: string,
    entries: string[]
  ): Promise<{ bills: number; receivables: number }> {
    return this.http
      .patch(
        `/v1/companies/${companyId}/entries/delete/undo`,
        {},
        {
          params: {
            entries,
          },
        }
      )
      .then((response) => response.data);
  }

  async copyEntries(companyId: string, payload: CopyEntries): Promise<Entry[]> {
    return this.http
      .post(`/v1/companies/${companyId}/entries/copy`, payload)
      .then((response) => response.data);
  }
}
