import { Adapter } from "@/@types";

import {
  Associate,
  CreateAssociate,
  UpdateAssociate,
} from "@/@types/associates";

export class AssociatesService {
  constructor(private readonly http: Adapter) {}

  async getAssociate(
    companyId: string,
    associateId: string
  ): Promise<Associate> {
    return this.http
      .get(`/v1/companies/${companyId}/associates/${associateId}`)
      .then((response) => response.data);
  }

  async getAssociates(companyId: string): Promise<Associate[]> {
    return this.http
      .get(`/v1/companies/${companyId}/associates`)
      .then((response) => response.data);
  }

  async createAssociate(
    companyId: string,
    payload: CreateAssociate
  ): Promise<Associate> {
    return this.http
      .post(`/v1/companies/${companyId}/associates`, payload)
      .then((response) => response.data);
  }

  async updateAssociate(
    companyId: string,
    { associate_id, pro_labore_amount_cents, ...rest }: UpdateAssociate
  ): Promise<Associate> {
    return this.http
      .patch(`/v1/companies/${companyId}/associates/${associate_id}`, {
        pro_labore_amount_cents: Number(pro_labore_amount_cents),
        ...rest,
      })
      .then((response) => response.data);
  }
}
