import { Adapter } from "@/@types";

import { CreateTax, Tax } from "@/@types/tax";

export class TaxService {
  constructor(private readonly http: Adapter) {}

  async getTax(taxId: string, companyId: string): Promise<Tax> {
    return this.http
      .get(`/v1/taxes/${companyId}/detail/${taxId}`)
      .then((response) => response.data);
  }

  async getTaxes(
    companyId: string,
    { start_date, end_date }: { start_date?: string; end_date?: string }
  ): Promise<Tax[]> {
    return this.http
      .get(`/v1/taxes/${companyId}`, {
        params: {
          start_date,
          end_date,
        },
      })
      .then((response) => response.data);
  }

  async issueTax(taxId: string, companyId: string): Promise<Tax> {
    return this.http
      .post(`/v1/taxes/${companyId}/issue/${taxId}`)
      .then((response) => response.data);
  }

  async createTax(companyId: string, payload: CreateTax): Promise<Tax> {
    return this.http
      .post(`/v1/taxes/${companyId}`, payload)
      .then((response) => response.data);
  }
}
