import * as React from "react";

import { cn } from "@/lib/utils";
import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "./pagination";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./select";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "./tooltip";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "./dropdown-menu";
import { Button } from "./button";
import { Eye } from "@phosphor-icons/react";
import { Checkbox } from "./checkbox";
import { Table as ReactTable } from "@tanstack/react-table";
import { Counter } from "./counter";

const Table = React.forwardRef<
  HTMLTableElement,
  React.HTMLAttributes<HTMLTableElement>
>(({ className, ...props }, ref) => (
  <div className="relative xl:max-w-[calc(100vw-344px)] w-full overflow-auto rounded-md whitespace-nowrap">
    <table
      ref={ref}
      className={cn(
        "w-full caption-bottom text-sm border-separate border-spacing-0",
        className
      )}
      {...props}
    />
  </div>
));
Table.displayName = "Table";

const TableHeader = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <thead ref={ref} className={cn("[&_tr]:border-b", className)} {...props} />
));
TableHeader.displayName = "TableHeader";

const TableBody = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <tbody
    ref={ref}
    className={cn("[&_tr:last-child]:border-0", className)}
    {...props}
  />
));
TableBody.displayName = "TableBody";

const TableFooter = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <tfoot
    ref={ref}
    className={cn(
      "border-t bg-muted/50 font-medium [&>tr]:last:border-b-0",
      className
    )}
    {...props}
  />
));
TableFooter.displayName = "TableFooter";

const TableRow = React.forwardRef<
  HTMLTableRowElement,
  React.HTMLAttributes<HTMLTableRowElement>
>(({ className, ...props }, ref) => (
  <tr
    ref={ref}
    role="row"
    className={cn(
      "border-b transition-colors hover:bg-muted/50 cursor-pointer data-[state=selected]:bg-muted leading-[52px]",
      className
    )}
    {...props}
  />
));
TableRow.displayName = "TableRow";

const TableHead = React.forwardRef<
  HTMLTableCellElement,
  React.ThHTMLAttributes<HTMLTableCellElement>
>(({ className, ...props }, ref) => (
  <th
    ref={ref}
    className={cn(
      "h-12 px-6 text-left align-middle font-medium text-gray-500 bg-gray-50 first:rounded-tl-sm first:rounded-bl-sm last:rounded-tr-sm last:rounded-br-sm border-t border-b first:border-l last:border-r [&:has([role=checkbox])]:pr-0 leading-[0px]",
      className
    )}
    {...props}
  />
));
TableHead.displayName = "TableHead";

const TableCell = React.forwardRef<
  HTMLTableCellElement,
  React.TdHTMLAttributes<HTMLTableCellElement>
>(({ className, ...props }, ref) => (
  <td
    ref={ref}
    className={cn(
      "px-6 py-4 align-middle [&:has([role=checkbox])]:pr-0 border-b",
      className
    )}
    {...props}
  />
));
TableCell.displayName = "TableCell";

const TableCaption = React.forwardRef<
  HTMLTableCaptionElement,
  React.HTMLAttributes<HTMLTableCaptionElement>
>(({ className, ...props }, ref) => (
  <caption
    ref={ref}
    className={cn("mt-4 text-sm text-muted-foreground", className)}
    {...props}
  />
));
TableCaption.displayName = "TableCaption";

type TableColumnVisibilityProps = {
  table: ReactTable<any>;
};

function TableColumnVisibility(inProps: TableColumnVisibilityProps) {
  const { table } = inProps;

  return (
    <TooltipProvider delayDuration={0}>
      <Tooltip>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <TooltipTrigger asChild>
              <Button
                size="icon"
                variant="outline"
                className="size-8 rounded-[8px] ml-auto text-primary"
              >
                <Counter className="-right-2.5">
                  <Eye size={16} />
                </Counter>
              </Button>
            </TooltipTrigger>
          </DropdownMenuTrigger>
          <TooltipContent side="left">Ocultar colunas</TooltipContent>
          <DropdownMenuContent side="left">
            {table
              .getAllColumns()
              .filter((column) => column.getCanHide())
              .map((column) => (
                <DropdownMenuItem
                  asChild
                  key={column.id}
                  className="gap-3"
                  onSelect={(e) => e.preventDefault()}
                >
                  <label htmlFor={`column-visibility-${column.id}`}>
                    <Checkbox
                      id={`column-visibility-${column.id}`}
                      checked={column.getIsVisible()}
                      onCheckedChange={(checked) =>
                        column.toggleVisibility(checked as boolean)
                      }
                    />
                    {String(column.columnDef.header)}
                  </label>
                </DropdownMenuItem>
              ))}
          </DropdownMenuContent>
        </DropdownMenu>
      </Tooltip>
    </TooltipProvider>
  );
}
TableColumnVisibility.displayName = "TableColumnVisibility";

type TablePaginationProps = {
  pageIndex: number;
  onPreviousPage: () => void;
  canPreviousPage: boolean;
  onNextPage: () => void;
  canNextPage: boolean;
  pageCount: number;
  onNavigatePage: (newPageIndex: number) => void;
  className?: string;
  onChangePageSize: (newPageSize: number) => void;
};

const TablePagination = (inProps: TablePaginationProps) => {
  const {
    pageIndex: initialPageIndex,
    pageCount,
    onPreviousPage,
    canPreviousPage,
    onNavigatePage,
    onNextPage,
    canNextPage,
    className,
    onChangePageSize,
  } = inProps;

  const pageIndex = initialPageIndex + 1;

  return (
    <Pagination className={className} role="pagination">
      <PaginationContent>
        <PaginationItem>
          <PaginationPrevious
            onClick={onPreviousPage}
            disabled={!canPreviousPage}
          />
        </PaginationItem>
        {pageIndex > 3 && (
          <PaginationItem>
            <PaginationEllipsis />
          </PaginationItem>
        )}
        {Array.from({ length: pageCount })
          .map((_, index) => index + 1)
          .filter(
            (page) =>
              page === 1 ||
              page === pageCount ||
              (page >= pageIndex - 1 && page <= pageIndex + 1)
          )
          .map((page) => (
            <PaginationItem key={page}>
              <PaginationLink
                isActive={page === pageIndex}
                onClick={() => onNavigatePage(page - 1)}
              >
                {page}
              </PaginationLink>
            </PaginationItem>
          ))}
        {pageIndex < pageCount - 2 && (
          <PaginationItem>
            <PaginationEllipsis />
          </PaginationItem>
        )}
        <PaginationItem>
          <PaginationNext onClick={onNextPage} disabled={!canNextPage} />
        </PaginationItem>
        <Select
          defaultValue="10"
          onValueChange={(newPageSize) => onChangePageSize(Number(newPageSize))}
        >
          <SelectTrigger className="h-10 gap-3">
            <SelectValue placeholder="10 por pag." />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              <SelectItem value="10">10 por pág.</SelectItem>
              <SelectItem value="50">50 por pág.</SelectItem>
              <SelectItem value="100">100 por pág.</SelectItem>
            </SelectGroup>
          </SelectContent>
        </Select>
      </PaginationContent>
    </Pagination>
  );
};

export {
  Table,
  TableHeader,
  TableBody,
  TableFooter,
  TableHead,
  TableRow,
  TableCell,
  TableCaption,
  TablePagination,
  TableColumnVisibility,
};
