import { useEffect } from "react";
import * as Sentry from "@sentry/react";

import { AuthStoreProvider } from "@/modules/auth";
import { ServicesProvider } from "@/services";
import { VersionCheckerContainer } from "@/modules/version-checker";

import ThemeProvider from "./theme-provider";
import { Router } from "./pages/router";

import "./globals.css";
import { withServiceWorker } from "./wrappers";

if (import.meta.env.MODE === "production") {
  Sentry.init({
    dsn: "https://7bf5795694f290454c190267beed7cd1@o4507749559631872.ingest.us.sentry.io/4508369961156608",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
      Sentry.captureConsoleIntegration({
        levels: ["error"],
      }),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: [
    //   "localhost",
    //   /^https:\/\/connect\.setfin\.com\.br/,
    // ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

function App() {
  useEffect(() => {
    window.setfinCache.stopCache();
  }, []);

  return (
    <ThemeProvider
      attribute="class"
      defaultTheme="light"
      storageKey="theme"
      enableSystem
      disableTransitionOnChange
    >
      <ServicesProvider>
        <AuthStoreProvider>
          <Router />
          <VersionCheckerContainer />
        </AuthStoreProvider>
      </ServicesProvider>
    </ThemeProvider>
  );
}

const component = withServiceWorker(App);

export default component;
