import * as React from "react";
import { format, isValid, parse } from "date-fns";
import { PopoverAnchor } from "@radix-ui/react-popover";

import { Calendar } from "./calendar";
import { Input, InputProps } from "./input";
import { Popover, PopoverContent } from "./popover";

export interface InputDateProps extends Omit<InputProps, "onBlur"> {
  onValueChange: (value?: string) => void;
  onBlur?: (newValue?: string) => void;
}

const InputDate = React.forwardRef<HTMLInputElement, InputDateProps>(
  ({ type = "text", value, onValueChange, onBlur, ...props }, ref) => {
    const [open, setOpen] = React.useState(false);

    const [month, setMonth] = React.useState(() => {
      if (value && typeof value === "string") {
        const parsedDate = new Date(value);

        if (isValid(parsedDate)) {
          return parsedDate;
        }
      }

      return new Date();
    });

    const [inputValue, setInputValue] = React.useState(() => {
      if (value && typeof value === "string") {
        const parsedDate = new Date(value);

        if (isValid(parsedDate)) {
          return format(new Date(parsedDate), "dd/MM/yyyy");
        }
      }

      return "";
    });

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
      setInputValue(event.currentTarget.value);

      const parsedDate = parse(
        event.currentTarget.value,
        "dd/MM/yyyy",
        new Date()
      );

      if (isValid(parsedDate)) {
        onValueChange(parsedDate.toISOString());
        setMonth(parsedDate);
      } else {
        onValueChange(undefined);
      }
    }

    return (
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverAnchor>
          <Input
            ref={ref}
            mask={["99/99/9999"]}
            placeholder="Selecione"
            className="w-full"
            value={inputValue}
            onChange={handleChange}
            onFocus={() => setOpen(true)}
            {...props}
          />
        </PopoverAnchor>

        <PopoverContent className="p-0 w-full">
          <Calendar
            mode="single"
            selected={new Date(value as string)}
            onSelect={(date) => {
              setOpen(false);
              onValueChange(date?.toISOString());
              setMonth(date || new Date());
              if (date) {
                setInputValue(format(date, "dd/MM/yyyy"));
              } else {
                setInputValue("");
              }
              onBlur?.(date?.toISOString());
            }}
            month={month}
            onMonthChange={setMonth}
            classNames={{
              day: "w-8 h-8 !rounded-sm",
              cell: "w-8 h-8",
              head_cell: "w-8",
            }}
          />
        </PopoverContent>
      </Popover>
    );
  }
);
InputDate.displayName = "InputDate";

export { InputDate };
