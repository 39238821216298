import { Adapter } from "@/@types";
import { Company } from "@/@types/company";

import {
  ServiceInvoice,
  ActivateInvoiceIssuancePayload,
  IssueServiceInvoicePayload,
  InvoiceStatus,
  ServiceInvoiceAttachments,
  IssueProductInvoicePayload,
  ProductInvoice,
  ProductInvoiceAttachments,
  IssueCustomerInvoicePayload,
  CustomerInvoice,
} from "@/@types/invoices";

export class InvoiceService {
  constructor(private readonly http: Adapter) {}

  async getMunicipalStatus(companyId: string): Promise<{
    authentication: Array<"certificado_a1" | "login_senha" | "token">;
    available_fields?: Record<string, string>;
  }> {
    return this.http
      .get(`/v1/service_invoices/${companyId}/municipal_status`)
      .then((response) => response.data);
  }

  async issueServiceInvoice(
    payload: IssueServiceInvoicePayload
  ): Promise<Company> {
    return this.http.post(`/v1/service_invoices`, payload);
  }

  async issueProductInvoice({
    invoice_products,
    ...rest
  }: IssueProductInvoicePayload): Promise<Company> {
    return this.http.post(`/v1/product_invoices`, {
      ...rest,
      invoice_products: invoice_products.filter(Boolean),
    });
  }

  async issueCustomerInvoice(
    payload: IssueCustomerInvoicePayload
  ): Promise<Company> {
    return this.http.post(`/v1/customer_invoices`, payload);
  }

  async getProductInvoices(
    companyId: string,
    {
      status,
      startDate,
      endDate,
    }: {
      status?: InvoiceStatus;
      startDate?: string;
      endDate?: string;
    } = {}
  ): Promise<ProductInvoice[]> {
    return this.http
      .get(`/v1/product_invoices`, {
        params: {
          companyId,
          status,
          start_date: startDate,
          end_date: endDate,
        },
      })
      .then((response) => response.data);
  }

  async getCustomerInvoices(
    companyId: string,
    status?: InvoiceStatus
  ): Promise<CustomerInvoice[]> {
    return this.http
      .get(`/v1/customer_invoices`, {
        params: {
          companyId,
          status,
        },
      })
      .then((response) => response.data);
  }

  async getServiceInvoices(
    companyId: string,
    {
      status,
      startDate,
      endDate,
      received,
    }: {
      status?: InvoiceStatus;
      startDate?: string;
      endDate?: string;
      received?: boolean;
    } = {}
  ): Promise<ServiceInvoice[]> {
    return this.http
      .get(`/v1/service_invoices`, {
        params: {
          companyId,
          status,
          start_date: startDate,
          end_date: endDate,
          received,
        },
      })
      .then((response) => response.data);
  }

  async getServiceInvoice(invoiceId: string): Promise<ServiceInvoice> {
    return this.http
      .get(`/v1/service_invoices/${invoiceId}`)
      .then((response) => response.data);
  }

  async getServiceAttachments(
    invoiceId: string
  ): Promise<ServiceInvoiceAttachments> {
    return this.http
      .get(`/v1/service_invoices/${invoiceId}/attachments`)
      .then((response) => response.data);
  }

  async getProductInvoice(invoiceId: string): Promise<ProductInvoice> {
    return this.http
      .get(`/v1/product_invoices/${invoiceId}`)
      .then((response) => response.data);
  }

  async getProductAttachments(
    invoiceId: string
  ): Promise<ProductInvoiceAttachments> {
    return this.http
      .get(`/v1/product_invoices/${invoiceId}/attachments`)
      .then((response) => response.data);
  }

  async getInvoiceIssuanceStatus(
    companyId: string
  ): Promise<{ status: "active" | "pending"; missing: string[] }> {
    return this.http
      .get(`/v1/companies/${companyId}/invoice_issuance`)
      .then((response) => response.data);
  }

  async activateInvoiceIssuance(
    payload: ActivateInvoiceIssuancePayload
  ): Promise<{ enabled: boolean }> {
    return this.http
      .post(
        `/v1/companies/${payload.companyId}/activate_invoice_issuance`,
        payload
      )
      .then((response) => response.data);
  }

  async activateReceiveInvoices(
    companyId: string
  ): Promise<{ enabled: boolean }> {
    return this.http
      .post(`/v1/companies/${companyId}/activate_receive_invoices`)
      .then((response) => response.data);
  }

  async deleteCustomerInvoice(invoiceId: string): Promise<CustomerInvoice> {
    return this.http.delete(`/v1/customer_invoices/${invoiceId}/cancel`);
  }

  async deleteServiceInvoice(invoiceId: string): Promise<ServiceInvoice> {
    return this.http.delete(`/v1/service_invoices/${invoiceId}/cancel`);
  }

  async deleteProductInvoice(invoiceId: string): Promise<ProductInvoice> {
    return this.http.delete(`/v1/product_invoices/${invoiceId}/cancel`);
  }
}
