import { Adapter } from "@/@types";
import { Boleto } from "@/@types/boleto";

export class BoletoService {
  constructor(private readonly http: Adapter) {}

  async getBoleto({
    digitable_line,
  }: {
    digitable_line: string;
  }): Promise<Boleto> {
    return this.http
      .get(`/v1/boleto/boleto_id`, {
        params: {
          digitable_line,
        },
      })
      .then((response) => response.data);
  }
}
