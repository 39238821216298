import { Adapter } from "@/@types";
import {
  BankStatementEntry,
  BankStatementEntryKind,
  CreateEntries,
} from "@/@types/bank-statements";

export class BankStatementsService {
  constructor(private readonly http: Adapter) {}

  async getEntries(
    companyId: string,
    params?: {
      bank_account_ids?: string[];
      start_date?: string;
      end_date?: string;
      kind?: BankStatementEntryKind;
      to_conciliation?: boolean;
      receivable_id?: string;
      bill_id?: string;
    }
  ): Promise<BankStatementEntry[]> {
    return this.http
      .get(`/v1/companies/${companyId}/bank_statements`, {
        params,
      })
      .then((response) => response.data);
  }

  async getEntry(
    companyId: string,
    bankStatementEntryId: string
  ): Promise<BankStatementEntry> {
    return this.http
      .get(
        `/v1/companies/${companyId}/bank_statements/${bankStatementEntryId}/entries`
      )
      .then((response) => response.data);
  }

  async uploadEntries(
    companyId: string,
    file: File
  ): Promise<{
    entries: BankStatementEntry[];
    routing_number: string | null;
  }> {
    return this.http
      .postForm(`/v1/companies/${companyId}/bank_statements/upload`, {
        file,
      })
      .then((response) => response.data);
  }

  async createEntries(
    companyId: string,
    payload: CreateEntries
  ): Promise<unknown> {
    return this.http
      .post(`/v1/companies/${companyId}/bank_statements`, payload)
      .then((response) => response.data);
  }
}
