import { forwardRef } from "react";

import { City } from "@/@types/address";
import { Autocomplete, AutocompleteProps, Skeleton } from "@/components/ui";

import { useCities } from "../hooks";

type Props = {
  state?: string | null;
  onCityChange: (city?: City) => void;
} & Omit<AutocompleteProps, "options">;

export const CitiesContainer = forwardRef<HTMLInputElement, Props>(
  function CitiesContainer(inProps, ref) {
    const { state, onValueChange, onCityChange } = inProps;

    const { cities, isLoading } = useCities(state);

    function handleChange(value?: string | string[] | null) {
      onValueChange(value);

      const city = cities.find((city) => String(city.id) === value);

      onCityChange(city);
    }

    if (isLoading) {
      return <Skeleton className="h-12 w-full" />;
    }

    return (
      <Autocomplete
        ref={ref}
        options={cities.map((city) => ({
          label: city.name,
          value: String(city.id),
        }))}
        {...inProps}
        loading={isLoading}
        onValueChange={handleChange}
        empty="Cidade não encontrada"
      />
    );
  }
);
