export const URL_USER_DATA = "/user-data";
export const URL_USER_ACCESS = "/user-access";
export const URL_VERIFY_OTP = "/verify-otp";
export const URL_LOGIN = "/login";
export const URL_IN_QUEUE_PHASE = "/in-queue-phase";
export const URL_SIGN_UP = "/sign-up";
export const URL_COMPANY = "/company";
export const URL_COMPANY_DOCUMENT_NUMBER = "/company-document-number";
export const URL_DOCUMENT_NUMBER = "/document-number";
export const URL_CONNECTION = "/connection";
export const URL_DASHBOARD = "/dashboard";
export const URL_OPEN_FINANCE = "/open-finance";
export const URL_CASH_FLOW = "/cash-flow";
export const URL_REPORTS = "/reports";
export const URL_ADDRESS = "/address";
export const URL_ANNUAL_BILLING_STATEMENTS = "/annual-billing-statements";
export const URL_PIX = "/pix";
export const URL_STATEMENTS = "/statements";
export const URL_EMAIL_AND_PHONE = "/email-and-phone";
export const URL_HISTORY = "/history";
export const URL_FEEDBACK = "/feedback";
export const URL_DEPENDENT = "/dependent";
export const URL_ASSOCIATES = "/associates";
export const URL_CONTACTS = "/contacts";
export const URL_CATEGORY = "/category";
export const URL_CATEGORIES = "/categories";
export const URL_DOCUMENTS = "/documents";
export const URL_BUDGET = "/budget";
export const URL_CHECKOUT = "/checkout";
export const URL_CREATE = "/create";
export const URL_SELECTION = "/selection";
export const URL_BILLING = "/billing";
export const URL_INITIAL_SETUP = "/initial-setup";
export const URL_ARCHIVE = "/archive";
export const URL_INFORMATION = "/information";
export const URL_DETAIL = "/detail";
export const URL_SUBSCRIPTIONS = "/subscriptions";
export const URL_SUBSCRIBE = "/subscribe";
export const URL_PRICE = "/price";
export const URL_BANK_ACCOUNTS = "/bank-accounts";
export const URL_BANK_INSTITUTION = "/institution";
export const URL_ATTACH = "/attach";
export const URL_SERVICES = "/services";
export const URL_IMPORT = "/import";
export const URL_INVOICES = "/invoices";
export const URL_ISSUE = "/issue";
export const URL_SETTINGS = "/settings";
export const URL_TAX = "/tax";
export const URL_TAXES = "/taxes";
export const URL_PRODUCTS = "/products";
export const URL_SCHEDULE = "/schedule";
export const URL_BENEFITS = "/benefits";
export const URL_DELETE = "/delete";
export const URL_PROFILE = "/profile";
export const URL_PRICE_AND_STOCKS = "/price-and-stocks";
export const URL_UPDATE = "/update";
export const URL_REPLICATE = "/replicate";
export const URL_PENDING = "/pending";
export const URL_REACHED_LIMIT_USAGE = "/reached-limit-usage";
export const URL_BILLS = "/bills";
export const URL_TRANSFERS = "/transfers";
export const URL_CONFIRM = "/confirm";
export const URL_RECEIVABLES = "/receivables";
export const URL_UPLOAD = "/upload";
export const URL_DOWNLOAD = "/download";
export const URL_CONCILIATION = "/conciliation";
export const URL_LEAD = "/lead";
export const URL_SELECT = "/select";
export const URL_WELCOME = "/welcome";
export const URL_PAYMENT = "/payment";
export const URL_LOGOUT = "/logout";
export const URL_FILTER = "/filter";
