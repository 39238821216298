import * as Sentry from "@sentry/react";
import * as amplitude from "@amplitude/analytics-browser";
import { sessionReplayPlugin } from "@amplitude/plugin-session-replay-browser";
import { Experiment, ExperimentClient } from "@amplitude/experiment-js-client";
import { plugin as engagementPlugin } from "@amplitude/engagement-browser";

import { Analytics } from "@/@types/analytics";

export class AnalyticsAdapter extends Analytics {
  private experiment: ExperimentClient | null = null;
  private engagement: ReturnType<typeof engagementPlugin> | null = null;

  async init(apiKey: string) {
    if (import.meta.env.MODE === "test" || !apiKey) return;

    amplitude.init(apiKey, {
      autocapture: true,
      offline: true,
    });

    this.experiment = Experiment.initializeWithAmplitudeAnalytics(apiKey, {
      retryFetchOnFailure: false,
      debug: true,
    });

    const sessionReplay = sessionReplayPlugin({
      sampleRate: 1,
    });

    const engagement = engagementPlugin();

    this.engagement = engagement;

    amplitude.add(sessionReplay);
    amplitude.add(engagement);
  }

  setUser(userId?: string): void {
    amplitude.setUserId(userId);
    if (this.experiment) {
      this.experiment.fetch({
        user_id: userId,
      });
    }
    if (userId) {
      Sentry.setUser({ id: userId });
    } else {
      Sentry.setUser(null);
    }
  }

  setSessionId(sessionId: number): void {
    amplitude.setSessionId(sessionId);
  }

  event(eventName: string, context: Record<string, any>): void {
    amplitude.track(eventName, context);
  }

  getExperiment() {
    if (this.experiment) {
      this.experiment.fetch();
    }

    return this.experiment;
  }
}
