import { forwardRef } from "react";
import {
  addMonths,
  endOfMonth,
  endOfYear,
  format,
  startOfMonth,
  startOfToday,
  startOfYear,
  subDays,
  subMonths,
} from "date-fns";
import {
  DateRange,
  DayPicker,
  SelectRangeEventHandler,
} from "react-day-picker";

import { Button, ButtonProps } from "./button";
import { Calendar } from "./calendar";
import { Popover, PopoverContent, PopoverTrigger } from "./popover";
import { ptBR } from "date-fns/locale/pt-BR";
import { cn } from "@/lib/utils";
import { CalendarBlank, CaretLeft, CaretRight } from "@phosphor-icons/react";

export interface InputDateRangePickerProps
  extends Omit<React.HTMLAttributes<HTMLButtonElement>, "onChange" | "value"> {
  value?: DateRange;
  onChange: SelectRangeEventHandler;
  disabledDays?: React.ComponentProps<typeof DayPicker>["disabled"];
  placeholder?: string;
  size?: ButtonProps["size"];
  fullCalendar?: boolean;
  extraControls?: boolean;
}

const InputDateRangePicker = forwardRef<
  HTMLButtonElement,
  InputDateRangePickerProps
>((inProps, ref) => {
  const {
    value,
    onChange,
    disabledDays,
    placeholder = "Selecione uma data",
    size = "small",
    fullCalendar = false,
    extraControls = false,
    ...rest
  } = inProps;

  const presets = [
    { label: "Hoje", value: "today" },
    { label: "Ontem", value: "yesterday" },
    { label: "Últimos 7 dias", value: "last7days" },
    { label: "Este mês", value: "thisMonth" },
    { label: "Mês passado", value: "lastMonth" },
    { label: "Últimos 12 meses", value: "last12Months" },
    { label: "Este ano", value: "thisYear" },
  ];

  const getDateRangeForPreset = (preset: string) => {
    const today = startOfToday();

    switch (preset) {
      case "today":
        return { from: today, to: today };
      case "yesterday":
        return { from: subDays(today, 1), to: subDays(today, 1) };
      case "last7days":
        return { from: subDays(today, 7), to: today };
      case "thisMonth":
        return { from: startOfMonth(today), to: endOfMonth(today) };
      case "lastMonth":
        return {
          from: startOfMonth(subMonths(today, 1)),
          to: endOfMonth(subMonths(today, 1)),
        };
      case "last12Months":
        return { from: subMonths(today, 12), to: today };
      case "thisYear":
        return { from: startOfYear(today), to: endOfYear(today) };
      default:
        return { from: startOfYear(today), to: endOfYear(today) };
    }
  };

  const isPresetActive = (preset: string) => {
    const presetRange = getDateRangeForPreset(preset);
    return (
      presetRange.from?.getTime() === value?.from?.getTime() &&
      presetRange.to?.getTime() === value?.to?.getTime()
    );
  };

  function handlePresetSelect(preset: string) {
    const range = getDateRangeForPreset(preset);

    if (range) {
      const selectedFrom = range.from || new Date();

      const event = {} as React.MouseEvent;

      onChange(range, selectedFrom, {}, event);
    }
  }

  function handlePreviousMonth() {
    if (value?.from && value?.to) {
      const newFrom = startOfMonth(subMonths(value.from, 1));
      const newTo = endOfMonth(subMonths(value.to, 1));

      onChange(
        { from: newFrom, to: newTo },
        newFrom,
        {},
        {} as React.MouseEvent
      );
    }
  }

  function handleNextMonth() {
    if (value?.from && value?.to) {
      const newFrom = startOfMonth(addMonths(value.from, 1));
      const newTo = endOfMonth(addMonths(value.to, 1));

      onChange(
        { from: newFrom, to: newTo },
        newFrom,
        {},
        {} as React.MouseEvent
      );
    }
  }

  return (
    <div className="flex items-center gap-1">
      {extraControls && (
        <Button
          className={cn({
            "size-9 rounded-sm": size === "small",
            "size-12 rounded-md": size === "default",
          })}
          size="icon"
          variant="outline"
          onClick={handlePreviousMonth}
        >
          <CaretLeft />
        </Button>
      )}
      <Popover>
        <PopoverTrigger asChild>
          <Button ref={ref} {...rest} variant="outline" size={size}>
            {size === "icon" ? (
              <CalendarBlank size={20} />
            ) : (
              <>
                {value?.from ? (
                  value.to ? (
                    <span className="capitalize">
                      {format(value.from, "LLL dd, y", {
                        locale: ptBR,
                      })}{" "}
                      -{" "}
                      {format(value.to, "LLL dd, y", {
                        locale: ptBR,
                      })}
                    </span>
                  ) : (
                    <span className="capitalize">
                      {format(value.from, "LLL dd, y", {
                        locale: ptBR,
                      })}
                    </span>
                  )
                ) : (
                  <span>{placeholder}</span>
                )}
              </>
            )}
          </Button>
        </PopoverTrigger>

        <PopoverContent className="flex gap-6 p-0 w-full">
          {fullCalendar && (
            <div className="flex flex-col items-start gap-3 p-3 border-r">
              {presets.map(({ label, value }) => (
                <Button
                  key={value}
                  className={cn(
                    "w-full text-left justify-start text-sm font-normal text-muted-foreground hover:bg-blue-50 hover:text-blue-700 hover:font-medium",
                    isPresetActive(value) && "text-blue-700 font-medium"
                  )}
                  variant="ghost"
                  size="small"
                  onClick={() => handlePresetSelect(value)}
                >
                  {label}
                </Button>
              ))}
            </div>
          )}
          <Calendar
            mode="range"
            selected={value}
            onSelect={onChange}
            defaultMonth={value?.from}
            numberOfMonths={2}
            disabled={disabledDays}
            classNames={{
              day: "size-8",
              cell: "size-8",
              head_cell: "w-8",
              head_row: "!gap-0",
              row: "!gap-0",
            }}
          />
        </PopoverContent>
      </Popover>
      {extraControls && (
        <Button
          className={cn({
            "size-9 rounded-sm": size === "small",
            "size-12 rounded-md": size === "default",
          })}
          size="icon"
          variant="outline"
          onClick={handleNextMonth}
        >
          <CaretRight />
        </Button>
      )}
    </div>
  );
});
InputDateRangePicker.displayName = "InputDateRangePicker";

export { InputDateRangePicker };
