/* eslint-disable @next/next/no-img-element */

import React, { ReactNode, useMemo } from "react";
import { CaretDown, X } from "@phosphor-icons/react";

import { cn } from "@/lib/utils";
import { Command as CommandPrimitive } from "cmdk";
import { useVirtualizer } from "@tanstack/react-virtual";
import { PopoverAnchor } from "@radix-ui/react-popover";
import {
  CommandEmpty,
  CommandGroup,
  CommandItem,
  CommandList,
} from "./command";
import { Input, inputClassName } from "./input";
import { Skeleton } from "./skeleton";
import { Separator } from "./separator";
import { Popover, PopoverContent } from "./popover";
import { ScrollArea, ScrollBar } from "./scroll-area";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "./tooltip";
import { TooltipPortal } from "@radix-ui/react-tooltip";

type Option = {
  label: string;
  value: string;
  searchTerm?: string | null;
  parent?: boolean;
  endAdornment?: ReactNode;
};

export type AutocompleteProps = {
  name?: string;
  mask?: string[];
  placeholder?: string;
  empty?: React.ReactNode;
  options: Option[];
  value: string | string[];
  loading?: boolean;
  shouldUseValueOnLabel?: boolean;
  disabled?: boolean;
  action?: React.ReactNode;
  onValueChange: (value?: string | string[] | null) => void;
  onEnter?: () => void;
  freeSolo?: boolean;
  multiple?: boolean;
  allowClear?: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

function AutocompleteList({
  filtered,
  onSelect,
}: {
  filtered: Option[];
  onSelect: (option: Option) => void;
}) {
  const parentRef = React.useRef<HTMLDivElement>(null);

  const rowVirtualizer = useVirtualizer({
    overscan: 5,
    count: filtered?.length || 0,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 35,
    getItemKey: (index) => filtered?.[index]?.value || "",
  });

  const items = rowVirtualizer.getVirtualItems() || [];

  return (
    <ScrollArea ref={parentRef} className="max-h-[300px]">
      <CommandList className="p-[4px]">
        <CommandGroup
          className="flex flex-col w-full relative"
          style={{
            position: "relative",
            height: `${rowVirtualizer.getTotalSize()}px`,
          }}
        >
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              transform: `translateY(${items[0]?.start ?? 0}px)`,
            }}
          >
            {items?.map((virtualRow) => (
              <CommandItem
                key={virtualRow.key}
                data-index={virtualRow.index}
                ref={(el) => rowVirtualizer.measureElement(el)}
                disabled={!!filtered?.[virtualRow.index]?.parent}
                value={filtered?.[virtualRow.index]?.label}
                onMouseDown={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                }}
                onSelect={() =>
                  filtered && onSelect(filtered?.[virtualRow.index])
                }
                className={cn("flex w-full items-center gap-2 cursor-pointer", {
                  "!text-slate-600 !text-xs font-medium !py-2.5":
                    !!filtered?.[virtualRow.index]?.parent,
                })}
              >
                {filtered?.[virtualRow.index]?.label}
                {filtered?.[virtualRow.index]?.endAdornment && (
                  <div className="ml-auto">
                    {filtered?.[virtualRow.index]?.endAdornment}
                  </div>
                )}
              </CommandItem>
            ))}
          </div>
        </CommandGroup>
      </CommandList>
      <ScrollBar orientation="vertical" />
    </ScrollArea>
  );
}

const Autocomplete = React.forwardRef<HTMLDivElement, AutocompleteProps>(
  (
    {
      name,
      allowClear = false,
      placeholder = "Selecione",
      shouldUseValueOnLabel = false,
      empty,
      options,
      loading,
      disabled,
      value,
      onValueChange,
      onBlur,
      className,
      action,
      freeSolo = false,
      multiple = false,
      mask,
      "aria-label": ariaLabel,
      onEnter,
    },
    ref
  ) => {
    const inputRef = React.useRef<HTMLInputElement>(null);

    const [isOpen, setOpen] = React.useState(false);
    const [isDirty, setIsDirty] = React.useState(false);
    const [inputValue, setInputValue] = React.useState<string | null>(() => {
      if (freeSolo) {
        return (
          options.find((option) => option.value === value)?.label ||
          (value as string) ||
          null
        );
      }

      return options.find((option) => option.value === value)?.label || null;
    });

    const selected = React.useMemo(() => {
      if (multiple && Array.isArray(value)) {
        return options.filter((option) =>
          value.find((v) => option.value === v)
        ) as Option[];
      }

      return options.find((option) => option.value === value) as Option;
    }, [options, value, multiple]);

    const deferredInputValue = React.useDeferredValue(inputValue);

    const filtered = React.useMemo(() => {
      const base = Array.isArray(selected)
        ? options.filter(
            (option) => !selected.find((s) => s.value === option.value)
          )
        : options;

      if (!deferredInputValue || !isDirty) return base;

      return base.filter((option) => {
        const searchTerm = option.searchTerm || option.label;

        return searchTerm
          .toLowerCase()
          .includes(deferredInputValue.toLowerCase());
      });
    }, [selected, options, deferredInputValue, isDirty]);

    React.useEffect(() => {
      if (inputValue === null && selected && !Array.isArray(selected)) {
        setInputValue(selected.label);
      }
    }, [selected, inputValue]);

    const handleKeyDown = React.useCallback(
      (event: React.KeyboardEvent<HTMLDivElement>) => {
        const input = inputRef.current;
        if (!input) {
          return;
        }

        // This is not a default behaviour of the <input /> field
        if (event.key === "Enter" && input.value !== "") {
          onEnter?.();

          if (freeSolo) {
            input.blur();
            return;
          }

          const optionToSelect = options.find(
            (option) => option.label === input.value
          );
          if (optionToSelect) {
            onValueChange?.(
              Array.isArray(selected)
                ? [...selected.map((s) => s.value), optionToSelect.value]
                : optionToSelect.value
            );
          }
          return;
        }

        if (event.key === "Tab") {
          setOpen(false);
          input.blur();
          return;
        }

        if (event.key === "Escape") {
          input.blur();
          return;
        }

        // Keep the options displayed when the user is typing
        if (!isOpen && !freeSolo) {
          setOpen(true);
        }
      },
      [options, onValueChange, isOpen, freeSolo, onEnter, selected]
    );

    const handleSelectOption = React.useCallback(
      (selectedOption: Option) => {
        if (!multiple) {
          if (shouldUseValueOnLabel) {
            setInputValue(selectedOption.value);
          } else {
            setInputValue(selectedOption.label);
          }
        }
        setOpen(false);
        if (Array.isArray(selected)) {
          onValueChange?.([
            ...selected.map((s) => s.value),
            selectedOption.value,
          ]);
        } else {
          onValueChange?.(selectedOption.value);
        }
        // This is a hack to prevent the input from being focused after the user selects an option
        // We can call this hack: "The next tick"
        setTimeout(() => {
          inputRef?.current?.blur();
        }, 0);
      },
      [multiple, selected, onValueChange, shouldUseValueOnLabel]
    );

    const handleFocus = React.useCallback(() => {
      setOpen(true);
    }, []);

    const handleOpenChange = React.useCallback(
      (open: boolean) => {
        setOpen(open);

        if (!open && !freeSolo && !Array.isArray(selected)) {
          setInputValue(selected?.label);
        }
      },
      [selected, freeSolo]
    );

    const handleValueChange = React.useCallback(
      (value: string) => {
        if (loading) return;

        if (!isDirty) {
          setIsDirty(true);
        }

        setInputValue(value);

        if (freeSolo) {
          onValueChange(value);
        }
      },
      [freeSolo, loading, onValueChange, isDirty]
    );

    const handleRemoveOption = React.useCallback(
      (valueToRemove: string) => {
        if (!Array.isArray(selected)) {
          return;
        }

        onValueChange?.(
          selected.filter((s) => s.value !== valueToRemove).map((s) => s.value)
        );
      },
      [selected, onValueChange]
    );

    const shouldPopoverContent = useMemo(() => {
      if (freeSolo && filtered?.length === 0) {
        return false;
      }

      return true;
    }, [freeSolo, filtered]);

    return (
      <Popover open={isOpen} modal onOpenChange={handleOpenChange}>
        <CommandPrimitive
          ref={ref}
          onKeyDown={handleKeyDown}
          shouldFilter={false}
          className={className}
        >
          <PopoverAnchor
            className={cn("group relative flex items-center", {
              "cursor-pointer": multiple,
            })}
            {...(multiple && {
              onClick: () => setOpen(true),
            })}
          >
            <Input
              role="combobox"
              mask={mask}
              ref={inputRef}
              aria-label={ariaLabel || name}
              value={inputValue || ""}
              onChange={(e) => handleValueChange(e.currentTarget.value)}
              onFocus={handleFocus}
              onBlur={onBlur}
              placeholder={placeholder}
              disabled={disabled || loading}
              className={cn("text-sm pr-8", {
                "!border-transparent h-auto p-0 w-auto rounded-none": multiple,
                "pr-20": allowClear,
              })}
              {...(multiple && {
                containerClassName: cn(inputClassName, "h-auto min-h-[48px]"),
                startAdornment: (
                  <div className="flex flex-wrap items-center gap-1 -ml-1 mr-1">
                    {Array.isArray(selected) &&
                      selected.map((option) => (
                        <TooltipProvider key={option.value} delayDuration={0}>
                          <Tooltip>
                            <TooltipTrigger
                              type="button"
                              className="flex items-center border whitespace-nowrap p-2 rounded-[6px]"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleRemoveOption(option.value);
                              }}
                            >
                              <span className="block text-xs truncate max-w-[15ch]">
                                {option.label}
                              </span>

                              <X size={12} className="ml-auto" />
                            </TooltipTrigger>
                            <TooltipPortal>
                              <TooltipContent
                                side="left"
                                className="max-w-[200px]"
                              >
                                {option.label}
                              </TooltipContent>
                            </TooltipPortal>
                          </Tooltip>
                        </TooltipProvider>
                      ))}
                  </div>
                ),
              })}
            />
            {allowClear && selected && (
              <button
                type="button"
                className={cn(
                  "transition-all opacity-0 absolute right-10 top-1/2 transform -translate-y-1/2 bg-transparent p-2 group-hover:opacity-100 hover:bg-gray-50 rounded-full",
                  {
                    "right-3": freeSolo,
                  }
                )}
                onClick={() => {
                  setInputValue("");
                  onValueChange(null);
                }}
              >
                <X size={14} />
              </button>
            )}
            {!freeSolo && (
              <div className="absolute right-3 top-1/2 transform -translate-y-1/2 pointer-events-none">
                <CaretDown size={16} />
              </div>
            )}
          </PopoverAnchor>
          {shouldPopoverContent && (
            <PopoverContent
              className="p-0"
              onOpenAutoFocus={(e) => e.preventDefault()}
            >
              {loading && (
                <CommandPrimitive.Loading>
                  <div className="p-1">
                    <Skeleton className="h-8 w-full" />
                  </div>
                </CommandPrimitive.Loading>
              )}
              {options.length > 0 && !loading && (
                <AutocompleteList
                  filtered={filtered || []}
                  onSelect={handleSelectOption}
                />
              )}
              {!loading && !freeSolo && (
                <CommandEmpty className="select-none rounded-sm px-2 py-3 flex flex-col items-center justify-center gap-3">
                  <img src="/images/box.png" width={68} height={81} alt="Box" />
                  <span className="text-center text-sm text-muted-foreground">
                    {empty}
                  </span>
                </CommandEmpty>
              )}
              {action && (
                <>
                  <Separator className="mt-1" />
                  <div className="p-1">{action}</div>
                </>
              )}
            </PopoverContent>
          )}
        </CommandPrimitive>
      </Popover>
    );
  }
);
Autocomplete.displayName = "Autocomplete";

export { Autocomplete };
